<template>
  <!-- Menu -->
  <ul class="nk-menu nk-menu-main">
    <router-link v-for="route in routes" :key="route.path" :to="route.path" custom
      v-slot="{ href, navigate, isActive, isExactActive }">

      <li :class="{ 'nk-menu-item': true, 'active': isExactActive || isActive, 'has-sub': route.submenu }">

        <a :href="href" @click="navigate" :class="route.submenu == true ? 'nk-menu-link nk-menu-toggle' : 'nk-menu-link'">
          <span class="nk-menu-text">{{ route.name }}</span>
        </a>

        <ul class="nk-menu-sub" v-if="route.submenu">
          <li class="nk-menu-item active current-page" v-for="son in route.routes" :key="son.path">
            <a :href="son.path" class="nk-menu-link">
              <span class="nk-menu-text">{{ son.name }}</span>
            </a>
          </li>
        </ul>

      </li>
    </router-link>
  </ul>
</template>

<script>
import useAuth from '@/providers/auth';
export default {
  name: "Menu",
  setup() {
    const { isAdmin } = useAuth();
    const { isSoloVista } = useAuth();
    const routes = [
      {
        path: '/sistema/inicio',
        name: 'Inicio',
      },
      {
        path: '/sistema/empleados',
        name: 'Colaboradores'
      }
    ];

    if (isAdmin.value) {
      routes.push({
        path: '/sistema/servicios',
        name: 'Servicios',
        submenu: false
      });
      routes.push({
        path: '/sistema/recorridos',
        name: 'Recorridos',
        submenu: true,
        routes: [{
          path: '/sistema/recorridos',
          name: 'Configuración',
        }, {
          path: '/sistema/puntos',
          name: 'Puntos de interés',
        }, {
          path: '/sistema/historial',
          name: 'Historial recorridos',
        }]
      });
      routes.push({
        path: '/sistema/usuarios',
        name: 'Usuarios',
        submenu: false
      });
      routes.push({
        path: '/sistema/configuracion',
        name: 'Configuracion',
        submenu: false
      });
    }

    if (isSoloVista.value) {
      routes.push({
        path: '/sistema/servicios',
        name: 'Servicios'
      });
    }

    return { routes };
  }
}
</script>

<style scoped></style>

<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Servicios</h3>
                  <div class="nk-block-des text-soft">
                    <p>Se encontraron un total de {{total}} servicios.</p>
                  </div>
                </div><!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                  <div class="toggle-wrap nk-block-tools-toggle">
                    <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                    <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                        <li v-if="!isSoloVista">
                          <select v-model="model.anio" class="form-control">
                            <option value="0">Selecciona un año</option>
                            <option v-for="anio in anios" :key="anio" :value="anio">{{anio}}</option>
                          </select>
                        </li>
                        <li v-if="!isSoloVista">
                          <select v-model="model.cuatrimestre" class="form-control">
                            <option value="0">Selecciona un cuatrimestre</option>
                            <option v-for="cuatrimestre in ['1', '2', '3']" :key="cuatrimestre" :value="cuatrimestre">{{cuatrimestre}}</option>
                          </select>
                        </li>
                        <li v-if="!isSoloVista">
                          <button type="button" class="btn btn-white btn-outline-dark" @click="generateSISUB()"><em class="icon ni ni-file-xls"></em><span>SISUB</span></button>
                        </li>
                        <li v-if="!isSoloVista">
                          <router-link to="/sistema/servicios/nuevo" class="btn btn-white btn-outline-dark"><em class="icon ni ni-user-add"></em><span>Nuevo Servicio</span></router-link>
                        </li>
                      </ul>
                    </div>
                  </div><!-- .toggle-wrap -->
                </div><!-- .nk-block-head-content -->
              </div><!-- .nk-block-between -->
            </div><!-- .nk-block-head -->
            <TableGrid :rows="rows" :filters="filters" :total="total" @update="update">
              <template #filters>
                <div class="card-tools between-start overflow-auto">
                  <div v-for="filter in filters" class="mr-3" :key="filter.key">
                    <input type="text" class="form-control w-min-100px" :placeholder="filter.label" v-model.lazy="filter.value">
                  </div>
                </div>
              </template>
              <template #header>
                <div class="nk-tb-col"><span class="sub-text">ID</span></div>
                <div class="nk-tb-col"><span class="sub-text">Nombre</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Fecha de Actualización</span></div>
                <div class="nk-tb-col text-center"><span class="sub-text">Detalles</span></div>
                <div class="nk-tb-col text-right" v-if="!isSoloVista"><span class="sub-text">Acciones</span></div>
              </template>
              <template v-slot:row="{row}">
                <div class="nk-tb-col">
                  <span>{{row.id_servicio}}</span>
                </div>
                <div class="nk-tb-col">
                  <div v-if="!isSoloVista">
                    <router-link :to="'/sistema/servicios/editar/'+row.id_servicio">
                      <span>{{row.nombre}}</span>
                    </router-link>
                  </div>
                  <div v-else>
                    <span>{{row.nombre}}</span>
                  </div>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{$filters.fecha(row.fecha_actualizacion)}}</span>
                </div>
                <div class="nk-tb-col text-center">
                  <button
                    type="button"
                    class="btn btn-round btn-icon btn-primary"
                    data-toggle="modal"
                    data-target="#dlgDetalles"
                    @click="load(row)"
                  >
                  <em class="icon ni ni-info"></em></button
                  >
                </div>
                <div class="nk-tb-col nk-tb-col-tools" v-if="!isSoloVista">
                  <ul class="nk-tb-actions gx-2">
                    <li>
                      <div class="drodown">
                        <a href="#" class="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li><router-link :to="'/sistema/servicios/editar/'+row.id_servicio"><em class="text-primary icon ni ni-edit"></em><span>Editar Servicio</span></router-link></li>
                            <li><a href="#" @click.prevent="remove(row)"><em class="text-danger icon ni ni-file-remove"></em><span>Eliminar Servicio</span></a></li>
                            <li>
                              <a href="#" @click.prevent="generateICSOE(row)">
                                <em class="text-success icon ni ni-file-xls"></em><span>ICSOE</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </TableGrid>
          </div>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="dlgDetalles">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <a href="#" class="close" data-dismiss="modal" aria-label="Close">
              <em class="icon ni ni-cross"></em>
            </a>
            <div class="modal-header">
              <h5 class="modal-title">Detalles del servicio: {{ servicio.nombre }}</h5>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="text-right">Nombre</td>
                    <td>{{ servicio.nombre }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Descripción</td>
                    <td>{{ servicio.descripcion }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Registro patronal</td>
                    <td>{{ servicio.registro_patronal }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Calle</td>
                    <td>{{ servicio.calle }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">No. Exterior</td>
                    <td>{{ servicio.no_exterior }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">No. Interior</td>
                    <td>{{ servicio.no_interior }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Colonia</td>
                    <td>{{ servicio.colonia }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Código postal</td>
                    <td>{{ servicio.codigo_postal }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Estado</td>
                    <td>{{ servicio.estado }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Municipio</td>
                    <td>{{ servicio.municipio }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Ubicación</td>
                    <td>{{ servicio.ubicacion }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppView>
</template>

<script>
import {reactive, ref, toRefs, defineComponent, computed} from "vue";
import AppView from "@/components/sistema/AppView";
import TableGrid from "@/components/TableGrid";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
import useAuth from "@/providers/auth";

export default defineComponent({
  name: "Servicios",
  components: {TableGrid, AppView},
  setup() {
    const {isAdmin, isSoloVista} = useAuth();
    const router = useRouter();

    const state = reactive({
      rows: [],
      total: 1,
      servicio: {}
    });

    const model = ref({
        anio: 0,
        cuatrimestre: 0
    });

    const anios = computed(() => {
      let max = new Date().getFullYear();
      let min = 2020;
      let anios = [];

      for (var i = max; i >= min; i--) {
        anios.push(i);
      }

      return anios;
    });

    const filters = ref([
      {
        key: 'nombre',
        label: 'Nombre',
        value: ''
      }
    ]);

    const { loading, make } = useApi();

    async function update(config){
      loading.message = 'Obteniendo información';
      let {data} = await make('servicios/get-all', config);
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          state.rows = data.rows;
          state.total = data.total;
        }
      }
    }

    async function remove(servicio){
      Swal.fire({
        title: 'Eliminar servicio',
        text: '¿Estás seguro que deseas eliminar el servicio ' + servicio.nombre + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async res => {
        if(res.isConfirmed){
          loading.message = 'Eliminando servicio';
          let {data} = await make('servicios/delete', {id_servicio: servicio.id_servicio});
          if(data === null){
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if(data.error){
              Swal.fire('Error', data.message, 'error');
            } else {
              router.go();
            }
          }
        }
      });
    }

    async function generateSISUB() {
      if (model.value.anio == 0 || model.value.cuatrimestre == 0) {
        Swal.fire('Información', "Debes de seleccionar el año y el cuatrimestre.", 'info');

        return false;
      }

      Swal.fire({
        title: 'Generar reporte SISUB',
        text: '¿Estás seguro que deseas generar el reporte SISUB?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let {data} = await make('servicios/excel/reporte-sisub', {model: model.value});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              location.href = data.url;
            }
          }
        }
      });
    }

    async function generateICSOE(servicio) {
      Swal.fire({
        title: 'Generar reporte ICSOE',
        text: '¿Estás seguro que deseas generar el reporte ICSOE del servicio: ' + servicio.nombre + '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let {data} = await make('servicios/excel/reporte-icsoe', {id_servicio: servicio.id_servicio});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              location.href = data.url;
            }
          }
        }
      });
    }

    async function load(servicio){
      state.servicio = servicio;
    }

    return { ...toRefs(state), model, anios, isAdmin, isSoloVista, filters, remove, update, generateSISUB, generateICSOE, load}
  }
});
</script>

<style scoped>
</style>
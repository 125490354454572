<template>
  <!-- main header @s -->
  <div class="nk-header nk-header-fluid is-theme"
    :style="config != null ? 'background: ' + config.color + ' !important; border-color: ' + config.color + ' !important' : ''">
    <div class="container-xl wide-xl">
      <div class="nk-header-wrap">
        <div class="nk-menu-trigger mr-sm-2 d-lg-none">
          <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em
              class="icon ni ni-menu"></em></a>
        </div>
        <div class="nk-header-brand">
          <router-link to="/" class="logo-link">
            <img class="logo" :src="config != null ? config.f_logo_oscuro.url : ''"
              :alt="config != null ? config.nombre : ''" />
          </router-link>
        </div>
        <!-- .nk-header-brand -->
        <div class="nk-header-menu" data-content="headerNav">
          <div class="nk-header-mobile">
            <div class="nk-header-brand">
              <router-link to="/" class="logo-link">
                <img class="logo" :src="config != null ? config.f_logo_blanco.url : ''"
                  :alt="config != null ? config.nombre : ''" />
              </router-link>
            </div>
            <div class="nk-menu-trigger mr-n2">
              <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em
                  class="icon ni ni-arrow-left"></em></a>
            </div>
          </div>
          <Menu></Menu>
        </div>
        <!-- .nk-header-menu -->
        <div class="nk-header-tools">
          <ul class="nk-quick-nav">
            <li v-if="isAdmin" class="dropdown notification-dropdown">
              <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                <div class="icon-status icon-status-success">
                  <em class="icon ni ni-bell"></em>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                <div class="dropdown-head">
                  <span class="sub-title nk-dropdown-title">Notificaciones</span>
                </div>
                <div class="dropdown-body">
                  <div class="nk-notification">
                    <router-link v-for="carta in cartas_no_antecedentes_penales" :key="carta"
                      :to="'/sistema/empleados/editar/' + carta.id_empleado">
                      <div class="nk-notification-item dropdown-inner">
                        <div class="nk-notification-icon">
                          <em class="
                              icon icon-circle
                              bg-warning-dim
                              ni ni-alert
                            "></em>
                        </div>
                        <div class="nk-notification-content">
                          <div class="nk-notification-text">
                            Expira pronto la <b>CARTA DE NO ANTECEDENTES PENALES</b> del empleado: <b>{{ carta.nombre }}
                              {{ carta.apellido_paterno }} {{ carta.apellido_materno }}</b>
                          </div>
                          <div class="nk-notification-time">Expira el: {{
                            parsearFecha(carta.fecha_vencimiento_no_antecedentes_penales, "DD [de] MMMM [de] YYYY") }},
                            <b>{{ carta.dias_fecha_vencimiento_no_antecedentes_penales }} día(s)</b></div>
                        </div>
                      </div>
                    </router-link>

                    <router-link v-for="certificado in certificados_medicos" :key="certificado"
                      :to="'/sistema/empleados/editar/' + certificado.id_empleado">
                      <div class="nk-notification-item dropdown-inner">
                        <div class="nk-notification-icon">
                          <em class="
                              icon icon-circle
                              bg-warning-dim
                              ni ni-alert
                            "></em>
                        </div>
                        <div class="nk-notification-content">
                          <div class="nk-notification-text">
                            Expira pronto el <b>CERTIFICADO MÉDICO</b> del empleado: <b>{{ certificado.nombre }} {{
                              certificado.apellido_paterno }} {{ certificado.apellido_materno }}</b>
                          </div>
                          <div class="nk-notification-time">Expira el: {{
                            parsearFecha(certificado.fecha_vencimiento_certificado_medico, "DD [de] MMMM [de] YYYY") }},
                            <b>{{ certificado.dias_fecha_vencimiento_certificado_medico }} día(s)</b></div>
                        </div>
                      </div>
                    </router-link>

                    <div v-if="!cartas_no_antecedentes_penales.length && !certificados_medicos.length">
                      <div class="nk-notification-item dropdown-inner">
                        <h5 class="text-center"><i class="ni ni-info"></i><br />No hay notificaciones por mostrar</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dropdown-foot center"><a href="javascript:void(0)">&nbsp;</a></div>
              </div>
            </li>

            <li class="dropdown user-dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <div class="user-toggle">
                  <div class="user-avatar">
                    <span>{{ $filters.initials(user) }}</span>
                  </div>
                  <div class="user-info d-none d-xl-block">
                    <div class="user-status text-warning text-capitalize">
                      {{ user?.role }}
                    </div>
                    <div class="user-name dropdown-indicator">
                      {{ user?.name }} {{ user?.lastname }}
                    </div>
                  </div>
                </div>
              </a>
              <div class="
                  dropdown-menu
                  dropdown-menu-md
                  dropdown-menu-right
                  dropdown-menu-s1
                  is-light
                ">
                <div class="
                    dropdown-inner
                    user-card-wrap
                    bg-lighter
                    d-none d-md-block
                  ">
                  <div class="user-card">
                    <div class="user-avatar">
                      <span>{{ $filters.initials(user) }}</span>
                    </div>
                    <div class="user-info">
                      <span class="lead-text">{{ user?.name }} {{ user?.lastname }}</span>
                      <span class="sub-text">{{ user?.email }}</span>
                    </div>
                  </div>
                </div>
                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li>
                      <router-link :to="{ name: 'Perfil' }"><em class="icon ni ni-user-alt"></em><span>Editar
                          Cuenta</span></router-link>
                    </li>
                  </ul>
                </div>
                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li>
                      <a href="#" class="text-danger" @click.prevent="confirmLogout"><em
                          class="icon ni ni-signout"></em><span>Cerrar Sesión</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- .dropdown -->
          </ul>
          <!-- .nk-quick-nav -->
        </div>
        <!-- .nk-header-tools -->
      </div>
      <!-- .nk-header-wrap -->
    </div>
    <!-- .container-fliud -->
  </div>
  <!-- main header @e -->
</template>

<script>
import Menu from "./Menu";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import useAuth from "@/providers/auth";
import { useApi } from "@/use/useApi";
import { ref, watch, reactive } from "vue";
import moment from 'moment';

export default {
  name: "Header",
  components: {
    Menu,
  },
  setup() {
    const { user, logout, isAdmin } = useAuth();
    const { make } = useApi();

    const router = useRouter();

    const config = ref();
    const cartas_no_antecedentes_penales = ref([]);
    const certificados_medicos = ref([]);

    const state = reactive({
      isAdmin: isAdmin
    });

    function confirmLogout() {
      Swal.fire({
        title: "Cerrar sesión",
        text: "¿Estás seguro que deseas cerrar sesión?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then((res) => {
        if (res.isConfirmed) {
          logout();
        }
      });
    }

    async function getConfig() {
      let { data } = await make("public/get-config", {});
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          config.value = data.model;

          document.title = config.value.nombre;
        }
      }
    }

    async function getNotifications() {
      let { data } = await make("generales/get-notificaciones", {});
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          cartas_no_antecedentes_penales.value = data.cartas_no_antecedentes_penales;
          certificados_medicos.value = data.certificados_medicos;
        }
      }
    }

    function parsearFecha(fecha, formato) {
      return moment(fecha).format(formato);
    }

    watch(user, () => {
      if (!user.value) {
        router.replace({ name: "Login" });
      }
    });

    if (state.isAdmin) {
      getNotifications();
    }

    getConfig();

    return { config, cartas_no_antecedentes_penales, certificados_medicos, user, logout, confirmLogout, isAdmin, parsearFecha };
  },
};
</script>

<style scoped>
.logo {
  height: 40px;
}
</style>
